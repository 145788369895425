import HeroText from './ueberUns';
import FaqSimple from './Fragen';
import ContactIcons from './Contact';


function Home() {

    return (<div>
        <HeroText/>
<FaqSimple/>
<ContactIcons/>
</div>)
}

export default Home